import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getEngagementState = (state) => state.engagements;

export const getAllEngagements = createSelector(getEngagementState, (engagementState) => engagementState.engagements);
export const getAllEngagementsForMenus = createSelector(getEngagementState, (engagementState) =>
    engagementState.engagements?.map((item) => ({ key: item?.id, value: item?.name })),
);

export const getPaginatedEngagements = createSelector(
    getEngagementState,
    (engagementState) => engagementState.paginatedEngagements.results,
);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// used to present 'getPaginatedEngagementParams' with a cleaned state to compare
const cleanPaginatedEngagementParams = createSelector(getEngagementState, (state) => {
    const cleanedParams = { ...state.paginatedEngagements.params };
    return cleanedParams;
});
// uses lodash.isequal to compare state of the object
export const getPaginatedEngagementsParams = createDeepEqualSelector(
    cleanPaginatedEngagementParams,
    (cleanedState) => cleanedState,
);

export const getTotalEngagementsCount = createSelector(
    getEngagementState,
    (engagementState) => engagementState.paginatedSessions.total,
);

// SESSIONS
export const getPaginatedSessions = createSelector(
    getEngagementState,
    (engagementState) => engagementState.paginatedSessions.results,
);

const cleanPaginatedSessionsParams = createSelector(getEngagementState, (state) => {
    const cleanedParams = { ...state.paginatedSessions.params };
    return cleanedParams;
});
// uses lodash.isequal to compare state of the object
export const getPaginatedSessionsParams = createDeepEqualSelector(
    cleanPaginatedSessionsParams,
    (cleanedState) => cleanedState,
);

export const getTotalSessionsCount = createSelector(
    getEngagementState,
    (engagementState) => engagementState.paginatedSessions.total,
);
export const getPaginatedSessionsStatus = createSelector(getEngagementState, (engagementState) => engagementState.paginatedSessionsStatus);
