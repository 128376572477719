const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_ENGAGEMENTS_BEGIN: true,
    GET_ENGAGEMENTS_SUCCESS: true,
    GET_ENGAGEMENTS_ERROR: true,
    GET_LEARNINIG_TOPICS_BEGIN: true,
    GET_LEARNINIG_TOPICS_SUCCESS: true,
    GET_LEARNINIG_TOPICS_ERROR: true,
    GET_PAGINATED_ENGAGEMENTS_BEGIN: true,
    GET_PAGINATED_ENGAGEMENTS_SUCCESS: true,
    GET_PAGINATED_ENGAGEMENTS_ERROR: true,
    UPDATE_PAGINATED_ENGAGEMENTS_PARAMS: true,
    CLEAR_PAGINATED_ENGAGEMENTS: true,
    DELETE_ENGAGEMENT_BEGIN: true,
    DELETE_ENGAGEMENT_SUCCESS: true,
    DELETE_ENGAGEMENT_ERROR: true,
    UPDATE_ENGAGEMENT_BEGIN: true,
    UPDATE_ENGAGEMENT_SUCCESS: true,
    UPDATE_ENGAGEMENT_ERROR: true,
    CREATE_ENGAGEMENT_BEGIN: true,
    CREATE_ENGAGEMENT_SUCCESS: true,
    CREATE_ENGAGEMENT_ERROR: true,
    ADD_COACHES_BEGIN: true,
    ADD_COACHES_SUCCESS: true,
    ADD_COACHES_ERROR: true,
    ADD_TALENT_BEGIN: true,
    ADD_TALENT_SUCCESS: true,
    ADD_TALENT_ERROR: true,
    REMOVE_USER_BEGIN: true,
    REMOVE_USER_SUCCESS: true,
    REMOVE_USER_ERROR: true,
    UPDATE_PAIRING_REQUEST_BEGIN: true,
    UPDATE_PAIRING_REQUEST_SUCCESS: true,
    UPDATE_PAIRING_REQUEST_ERROR: true,
    GET_PAGINATED_SESSIONS_BEGIN: true,
    GET_PAGINATED_SESSIONS_SUCCESS: true,
    GET_PAGINATED_SESSIONS_ERROR: true,
    UPDATE_PAGINATED_SESSIONS_PARAMS: true,
    CLEAR_PAGINATED_SESSIONS: true,
});
