import Typography from '@mui/material/Typography';
import { ThemeProvider, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import themer from '../../styles/material-theme';
import ErrorImg from './images/error.png';

const StyledHeader = styled(Typography)({
    fontSize: '6.0rem',
});

const StyledDescription = styled(Typography)({
    fontSize: '6.0rem',
    textAlign: 'center',
});

const ErrorBoundary = () => (
    <ThemeProvider theme={themer}>
        <main style={{ backgroundColor: '#F3F6F9' }}>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    minWidth: '100vw',
                }}
            >
                <StyledHeader variant="h1" color="primary">
                    Error: 500
                </StyledHeader>

                <StyledDescription variant="h3" color="primary">
                    Something went wrong on our end.
                </StyledDescription>

                <StyledDescription variant="h3" color="primary" pt={3}>
                    Please refresh the page to continue using Tandem.
                </StyledDescription>

                <img src={ErrorImg} alt="" style={{ maxWidth: '80%' }} />
            </Box>
        </main>
    </ThemeProvider>
);

export default ErrorBoundary;
