import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getUserState = (state) => state.users;

export const getPaginatedUsers = createSelector(getUserState, (userState) => userState.paginatedUsers.results);

export const getPaginatedUsersStatus = createSelector(getUserState, (userState) => userState.paginatedUsersStatus);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// used to present 'getPaginatedUserParams' with a cleaned state to compare
const cleanPaginatedUserParams = createSelector(getUserState, (state) => {
    const cleanedParams = { ...state.paginatedUsers.params };
    return cleanedParams;
});
// uses lodash.isequal to compare state of the object
export const getPaginatedUsersParams = createDeepEqualSelector(
    cleanPaginatedUserParams,
    (cleanedState) => cleanedState,
);

export const getTotalUsersCount = createSelector(getUserState, (userState) => userState.paginatedUsers.total);

export const getCertifications = createSelector(getUserState, (userState) => userState.certifications || []);

export const getDefaultRoles = createSelector(getUserState, (userState) => userState.defaultRoles || {});

export const getSimplifiedEngagementRoles = createSelector(
    getUserState,
    (userState) => userState.simplifiedEngagementRoles,
);
export const getSimplifiedPlatformRoles = createSelector(
    getUserState,
    (userState) => userState.simplifiedEngagementRoles,
);
export const getFlattenedRoles = createSelector(getUserState, (userState) => userState.flattenedRoles);
export const getFlattenedResourcesRoles = createSelector(
    getUserState,
    (userState) => userState.flattenedResourcesRoles,
);
