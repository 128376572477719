import UsersTypes from '../action-types/users';

const internals = {
    initial: () => ({
        paginatedUsers: {
            params: {
                page: null, // by setting null we can detect if the page has been hyrdated from url params
                pageSize: 10,
                sortBy: 'lastName',
                sortOrder: 'asc',
                filter: {},
            },
            results: null,
            total: null,
        },
        paginatedUsersStatus: {
            error: false,
            errorMsg: '',
            completed: false,
            cleared: true,
        },
        deleteUserStatus: {
            error: false,
            completed: false,
        },
        certifications: [],
        defaultRoles: {},
    }),
};

const UsersReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
        case UsersTypes.UPDATE_PAGINATED_USERS_PARAMS: {
            const newState = { ...state };
            newState.paginatedUsers.params = {
                ...state.paginatedUsers.params,
                ...payload,
            };
            return newState;
        }
        case UsersTypes.GET_PAGINATED_USERS_BEGIN:
            return {
                ...state,
                paginatedUsersStatus: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case UsersTypes.GET_PAGINATED_USERS_ERROR:
            return {
                ...state,
                paginatedUsersStatus: {
                    error: true,
                    errorMsg: '',
                    completed: true,
                },
            };
        case UsersTypes.GET_PAGINATED_USERS_SUCCESS:
            return {
                ...state,
                paginatedUsersStatus: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
                paginatedUsers: {
                    ...state.paginatedUsers,
                    results: payload.results,
                    total: payload.total,
                },
            };
        case UsersTypes.CLEAR_PAGINATED_USERS:
            return {
                ...state,
                paginatedUsersStatus: internals.initial().paginatedUsersStatus,
                paginatedUsers: internals.initial().paginatedUsers,
                paginatedUsersTypes: internals.initial().paginatedUsersTypes,
            };
        case UsersTypes.DELETE_USER_BEGIN:
            return {
                ...state,
                deleteUserStatus: {
                    error: false,
                    completed: false,
                },
            };
        case UsersTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUserStatus: {
                    error: false,
                    completed: true,
                },
            };
        case UsersTypes.DELETE_USER_ERROR:
            return {
                ...state,
                deleteUserStatus: {
                    error: true,
                    completed: true,
                },
            };

        case UsersTypes.GET_CERTIFICATIONS_SUCCESS:
            return {
                ...state,
                certifications: payload,
            };

        case UsersTypes.GET_PERMISSIONS_SUCCESS: {
            // break the payload into a simplified key/value object
            // used for building role filters
            const simplifiedEngagementRoles = [];

            // not yet in use
            const simplifiedPlatformRoles = [];

            // used in header menu
            const flattenedResourcesRoles = [];

            // used for matching role keys to display values
            const flattenedRoles = {};

            Object.keys(payload.firm.engagement).forEach((key) => {
                simplifiedEngagementRoles.push({
                    key,
                    value: payload.firm.engagement[key].label,
                    roleType: 'admin',
                    userType: 'firm',
                });
                flattenedRoles[key] = payload.firm.engagement[key].label;
            });

            Object.keys(payload.consultancy.engagement).forEach((key) => {
                simplifiedEngagementRoles.push({
                    key,
                    value: payload.consultancy.engagement[key].label,
                    roleType: 'admin',
                    userType: 'consultancy',
                });
                flattenedRoles[key] = payload.consultancy.engagement[key].label;
            });
            Object.keys(payload.participant).forEach((key) => {
                simplifiedEngagementRoles.push({
                    key,
                    value: payload.participant[key].label,
                    roleType: 'participant',
                    userType: 'all',
                });
                flattenedRoles[key] = payload.participant[key].label;
            });
            // platform roles
            Object.keys(payload.consultancy.platform).forEach((key) => {
                simplifiedPlatformRoles.push({
                    key,
                    value: payload.consultancy.platform[key].label,
                    roleType: 'admin',
                    userType: 'consultancy',
                });
                flattenedRoles[key] = payload.consultancy.platform[key].label;
            });
            Object.keys(payload.firm.platform).forEach((key) => {
                simplifiedPlatformRoles.push({
                    key,
                    value: payload.firm.platform[key].label,
                    roleType: 'admin',
                    userType: 'consultancy',
                });
                flattenedRoles[key] = payload.firm.platform[key].label;
            });
            // resources roles
            Object.keys(payload.resources).forEach((key) => {
                flattenedResourcesRoles[key] = payload.resources[key].label;
            });

            return {
                ...state,
                defaultRoles: payload,
                simplifiedEngagementRoles,
                simplifiedPlatformRoles,
                flattenedRoles,
                flattenedResourcesRoles,
            };
        }

        default:
        // do nothing
    }
    return state;
};

export default UsersReducer;
