const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_FIRMS_BEGIN: true,
    GET_FIRMS_SUCCESS: true,
    GET_FIRMS_ERROR: true,
    GET_PAGINATED_FIRMS_BEGIN: true,
    GET_PAGINATED_FIRMS_SUCCESS: true,
    GET_PAGINATED_FIRMS_ERROR: true,
    UPDATE_PAGINATED_FIRMS_PARAMS: true,
    CLEAR_PAGINATED_FIRMS: true,
    GET_FIRM_BEGIN: true,
    GET_FIRM_SUCCESS: true,
    GET_FIRM_ERROR: true,
    CREATE_FIRM_BEGIN: true,
    CREATE_FIRM_SUCCESS: true,
    CREATE_FIRM_ERROR: true,
    UPDATE_FIRM_BEGIN: true,
    UPDATE_FIRM_SUCCESS: true,
    UPDATE_FIRM_ERROR: true,
});
