import cloneDeep from 'lodash/cloneDeep';
import orderBy from 'lodash/orderBy';

import ResourcesTypes from '../action-types/resources';

const internals = {
    initial: () => ({
        learningTopics: [],
        libraries: [],
        remoteResourceParameters: [], // used for filters and other lists
        paginatedResources: {
            params: {
                page: null, // by setting null we can detect if the page has been hyrdated from url params
                pageSize: 10,
                // sortBy: 'name',
                // sortOrder: 'asc',
                filter: {},
            },
            results: null,
            total: null,
            countByStatus: null,
        },
        paginatedResourcesStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        deleteResourceStatus: {
            error: false,
            completed: false,
        },
    }),
};

const ResourcesReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
        case ResourcesTypes.UPDATE_PAGINATED_RESOURCES_PARAMS: {
            const newState = { ...state };
            newState.paginatedResources.params = {
                ...state.paginatedResources.params,
                ...payload,
            };
            return newState;
        }
        case ResourcesTypes.GET_PAGINATED_RESOURCES_BEGIN:
            return {
                ...state,
                paginatedResourcesStatus: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case ResourcesTypes.GET_PAGINATED_RESOURCES_ERROR:
            return {
                ...state,
                paginatedResourcesStatus: {
                    error: true,
                    errorMsg: '',
                    completed: true,
                },
            };
        case ResourcesTypes.GET_PAGINATED_RESOURCES_SUCCESS:
            return {
                ...state,
                paginatedResourcesStatus: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
                paginatedResources: {
                    ...state.paginatedResources,
                    results: payload.results,
                    total: payload.total,
                    countByStatus: payload.countByStatus,
                },
            };
        case ResourcesTypes.CLEAR_PAGINATED_RESOURCES:
            return {
                ...state,
                paginatedResourcesStatus: internals.initial().paginatedResourcesStatus,
                paginatedResources: internals.initial().paginatedResources,
                paginatedResourcesTypes: internals.initial().paginatedResourcesTypes,
            };
        case ResourcesTypes.DELETE_ENGAGEMENT_BEGIN:
            return {
                ...state,
                deleteResourceStatus: {
                    error: false,
                    completed: false,
                },
            };
        case ResourcesTypes.DELETE_ENGAGEMENT_SUCCESS:
            return {
                ...state,
                deleteResourceStatus: {
                    error: false,
                    completed: true,
                },
            };
        case ResourcesTypes.DELETE_ENGAGEMENT_ERROR:
            return {
                ...state,
                deleteResourceStatus: {
                    error: true,
                    completed: true,
                },
            };
        case ResourcesTypes.GET_RESOURCES_BEGIN:
            return {
                ...state,
                engagements: [],
            };
        case ResourcesTypes.GET_RESOURCES_SUCCESS:
            return {
                ...state,
                engagements: payload,
            };
        case ResourcesTypes.GET_LIBRARIES_BEGIN:
            return {
                ...state,
                learningTopics: [],
            };
        case ResourcesTypes.GET_LIBRARIES_SUCCESS: {
            const modifiedValues = cloneDeep(payload);
            // a list of all learning topics extracted from all libraries

            let learningTopics = modifiedValues.reduce(
                (acc, library) => [...acc, ...library.learningTopics],
                [],
            );

            // sort learningTopics to be caseinsensitive
            learningTopics = orderBy(
                learningTopics,
                [(topic) => topic.name.toLowerCase()],
                ['asc'],
            );
            return {
                ...state,
                libraries: modifiedValues,
                // learning topics here contain resources
                learningTopics,
            };
        }
        case ResourcesTypes.GET_REMOTE_PARAMETERS_SUCCESS: {
            // we need to normalize this data for filters
            const modifiedValues = cloneDeep(payload);

            modifiedValues.authors = modifiedValues.authors.map((item) => ({ key: item, value: item }));
            modifiedValues.publishers = modifiedValues.publishers.map((item) => ({ key: item, value: item }));
            modifiedValues.firms = modifiedValues.firms.map((item) => ({ key: item.id, value: item.name }));

            modifiedValues.types = orderBy(modifiedValues.types, ['value'], ['asc']);
            modifiedValues.experienceLevels = orderBy(modifiedValues.experienceLevels, ['value'], ['asc']);
            modifiedValues.publishers = orderBy(modifiedValues.publishers, ['value'], ['asc']);
            modifiedValues.practiceGroups = orderBy(modifiedValues.practiceGroups, ['value'], ['asc']);
            modifiedValues.learningSubtopics = orderBy(modifiedValues.learningSubtopics, ['value'], ['asc']);

            // learning topics here don't contain resources
            // they are used for filtering
            // a list of all learning topics extracted from all libraries
            modifiedValues.learningTopics = modifiedValues.libraries.reduce(
                (acc, library) => {
                    const topics = library.learningTopics.map((topic) => (
                        {
                            key: topic.id,
                            value: topic.name,
                        }
                    ));
                    return [...acc, ...topics];
                },
                [],
            );

            // sort learningTopics to be caseinsensitive
            modifiedValues.learningTopics = orderBy(
                modifiedValues.learningTopics,
                [(topic) => topic.value.toLowerCase()],
                ['asc'],
            );

            return {
                ...state,
                remoteResourceParameters: modifiedValues,
            };
        }
        default:
        // do nothing
    }
    return state;
};

export default ResourcesReducer;
