import { useState, useCallback } from 'react';

const useSnackbar = () => {
    const [snackbarOptions, setSnackbarOptions] = useState({});
    const [isOpen, setOpen] = useState(false);

    const handleOpenSnackbar = useCallback(
        (options) => {
            setSnackbarOptions(options);
            setOpen(true);
        },
        [setOpen, setSnackbarOptions],
    );

    const handleCloseSnackbar = useCallback(
        (event, reason) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        },
        [setOpen],
    );

    return {
        handleOpenSnackbar,
        handleCloseSnackbar,
        snackbarProps: {
            ...snackbarOptions,
            isOpen,
            handleClose: handleCloseSnackbar,
        },
    };
};

export default useSnackbar;
