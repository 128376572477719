import { combineReducers } from 'redux';
import App from './app';
import Auth from './auth';
import User from './user'; // logged in user
import Firms from './firms';
import Users from './users'; // users of the app
import Engagements from './engagements';
import Resources from './resources';

const Reducers = () =>
    combineReducers({
        app: App,
        auth: Auth,
        user: User,
        firms: Firms,
        users: Users,
        resources: Resources,
        engagements: Engagements,
    });

export default Reducers;
