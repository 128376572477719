import { Suspense, lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { SentryRoutes } from '../../utils/sentry';

const Login = lazy(() => import('../../routes/login'));
const ForgotPassword = lazy(() => import('../../routes/login/forgot-password'));
const ResetPassword = lazy(() => import('../../routes/login/reset-password'));
const SetPassword = lazy(() => import('../../routes/login/set-password'));
const ExpiredPassword = lazy(() => import('../../routes/login/expired-password'));
const Confirm = lazy(() => import('../../routes/login/2FA'));
const Error404 = lazy(() => import('../../routes/error/404'));
const PrivacyPolicy = lazy(() => import('../../routes/privacy-policy'));
const TermsOfService = lazy(() => import('../../routes/terms'));

const PublicLayout = () => (
    <Suspense fallback={<div />}>
        <main>
            <SentryRoutes>
                <Route path="/error/404" element={<Error404 />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Login />} />
                <Route path="/login/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:resetToken" element={<ResetPassword />} />
                <Route path="/accept-invitation/:inviteToken" element={<SetPassword />} />
                <Route path="/confirm/:proofToken" element={<Confirm />} />
                <Route path="/expired-password" element={<ExpiredPassword />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<TermsOfService />} />

                <Route path="/*" element={<Navigate replace to="/login" />} />
            </SentryRoutes>
        </main>
    </Suspense>
);

export default PublicLayout;
