import WebClient from '../utils/web-client';
import UserTypes from '../action-types/user';
import { APIError } from './app';

const GetUserBegin = () => ({
    type: UserTypes.GET_USER_BEGIN,
});
const GetUserSuccess = (payload) => ({
    type: UserTypes.GET_USER_SUCCESS,
    payload,
});
const GetUserError = () => ({
    type: UserTypes.GET_USER_ERROR,
});

// eslint-disable-next-line import/prefer-default-export
export const GetUser = () => async (dispatch) => {
    dispatch(GetUserBegin());

    try {
        const { data } = await WebClient.get('/user');
        dispatch(GetUserSuccess(data));
    } catch (error) {
        dispatch(APIError());
        dispatch(GetUserError());
    }
};

const UpdateUserBegin = () => ({
    type: UserTypes.UPDATE_USER_BEGIN,
});
const UpdateUserSuccess = (payload) => ({
    type: UserTypes.UPDATE_USER_SUCCESS,
    payload,
});
const UpdateUserError = () => ({
    type: UserTypes.UPDATE_USER_ERROR,
});

export const UpdateUser = (payload) => async (dispatch) => {
    dispatch(UpdateUserBegin());

    try {
        const { data } = await WebClient.patch('/user', payload);
        dispatch(UpdateUserSuccess(data));
    } catch {
        dispatch(UpdateUserError());
    }
};
