import WebClient from '../utils/web-client';
import FirmsTypes from '../action-types/firms';
import { APIError, APISuccess } from './app';
import removeEmptyArrays from '../utils/remove-empty-arrays';

import { getPaginatedFirmsParams } from '../selectors/firms';

const GetAllFirmsBegin = () => ({
    type: FirmsTypes.GET_FIRMS_BEGIN,
});
const GetAllFirmsSuccess = (payload) => ({
    type: FirmsTypes.GET_FIRMS_SUCCESS,
    payload,
});
const GetAllFirmsError = () => ({
    type: FirmsTypes.GET_FIRMS_ERROR,
});

export const GetAllFirms = () => async (dispatch) => {
    dispatch(GetAllFirmsBegin());

    try {
        const { data } = await WebClient.get('/firms');
        dispatch(GetAllFirmsSuccess(data));
    } catch (error) {
        dispatch(GetAllFirmsError());
    }
};

const GetPaginatedFirmsBegin = () => ({
    type: FirmsTypes.GET_PAGINATED_FIRMS_BEGIN,
});
const GetPaginatedFirmsSuccess = (payload) => ({
    type: FirmsTypes.GET_PAGINATED_FIRMS_SUCCESS,
    payload,
});
const GetPaginatedFirmsError = () => ({
    type: FirmsTypes.GET_PAGINATED_FIRMS_ERROR,
});

export const GetPaginatedFirms = () => async (dispatch, getState) => {
    dispatch(GetPaginatedFirmsBegin());

    const paginatedParams = getPaginatedFirmsParams(getState());
    if (paginatedParams.page !== null) {
        const payload = { ...paginatedParams };
        delete payload.page;
        delete payload.pageSize;
        delete payload.results;
        delete payload.total;
        payload.filter = removeEmptyArrays(payload.filter);

        try {
            const { data } = await WebClient.post(
                `/firms/${paginatedParams.page}/${paginatedParams.pageSize}`,
                payload,
            );
            dispatch(GetPaginatedFirmsSuccess(data));
        } catch (error) {
            dispatch(APIError());
            dispatch(GetPaginatedFirmsError());
        }
    }
};

export const UpdatePaginatedFirmsParams = (payload) => ({
    type: FirmsTypes.UPDATE_PAGINATED_FIRMS_PARAMS,
    payload,
});

export const ClearPaginatedFirms = () => ({
    type: FirmsTypes.CLEAR_PAGINATED_FIRMS,
});

const GetFirmBegin = () => ({
    type: FirmsTypes.GET_FIRM_BEGIN,
});
const GetFirmSuccess = () => ({
    type: FirmsTypes.GET_FIRM_SUCCESS,
});
const GetFirmError = () => ({
    type: FirmsTypes.GET_FIRM_ERROR,
});

export const GetFirm = (id, onSuccess, onError) => async (dispatch) => {
    dispatch(GetFirmBegin());

    try {
        const { data } = await WebClient.get(`/firms/${id}`);
        dispatch(GetFirmSuccess());
        if (onSuccess) onSuccess(data);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        dispatch(GetFirmError());
        dispatch(APIError('Error loading firm'));
        if (onError) onError(error);
    }
};

const CreateFirmBegin = () => ({
    type: FirmsTypes.CREATE_FIRM_BEGIN,
});
const CreateFirmSuccess = () => ({
    type: FirmsTypes.CREATE_FIRM_SUCCESS,
});
const CreateFirmError = () => ({
    type: FirmsTypes.CREATE_FIRM_ERROR,
});

export const CreateFirm = (payload, onSuccess) => async (dispatch) => {
    dispatch(CreateFirmBegin());

    try {
        const { data } = await WebClient.post('/firms', payload);
        dispatch(CreateFirmSuccess());
        dispatch(APISuccess('Firm successfully added.'));
        dispatch(GetPaginatedFirms());
        dispatch(GetAllFirms());
        if (onSuccess) onSuccess(data.id);
    } catch (error) {
        if (error.response.status === 409) {
            dispatch(APIError('A firm with this name already exists. Please enter a new firm name.'));
        } else {
            dispatch(APIError('Error creating firm. Please try again.'));
        }
        dispatch(CreateFirmError());
    }
};

const UpdateFirmBegin = () => ({
    type: FirmsTypes.UPDATE_FIRM_BEGIN,
});
const UpdateFirmSuccess = () => ({
    type: FirmsTypes.UPDATE_FIRM_SUCCESS,
});
const UpdateFirmError = () => ({
    type: FirmsTypes.UPDATE_FIRM_ERROR,
});

export const UpdateFirm = (id, data, onSuccess) => async (dispatch) => {
    dispatch(UpdateFirmBegin());

    try {
        await WebClient.patch(`/firms/${id}`, data);
        dispatch(UpdateFirmSuccess());
        dispatch(APISuccess('Firm successfully updated.'));
        dispatch(GetPaginatedFirms());
        dispatch(GetAllFirms());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(UpdateFirmError());
    }
};
