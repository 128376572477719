import WebClient from '../utils/web-client';
import EngagementsTypes from '../action-types/engagements';
import { APIError, APISuccess } from './app';
import { GetUser } from './user';

import { getPaginatedEngagementsParams, getPaginatedSessionsParams } from '../selectors/engagements';

const UpdatePairingRequestBegin = () => ({
    type: EngagementsTypes.UPDATE_PAIRING_REQUEST_BEGIN,
});
const UpdatePairingRequestSuccess = (payload) => ({
    type: EngagementsTypes.UPDATE_PAIRING_REQUEST_SUCCESS,
    payload,
});
const UpdatePairingRequestError = () => ({
    type: EngagementsTypes.UPDATE_PAIRING_REQUEST_ERROR,
});

export const UpdatePairingRequest =
    (engagementId, talentId, action, comment = null) =>
    async (dispatch) => {
        dispatch(UpdatePairingRequestBegin());

        const payload = {
            talentId,
            action,
        };
        if (comment) payload.comment = comment;

        try {
            await WebClient.patch(`/engagements/${engagementId}/pair-coach`, payload);
            dispatch(GetUser());
            if (action === 'accepted') dispatch(APISuccess('Pairing request accepted'));
            if (action === 'declined') dispatch(APISuccess('Pairing request declined'));
            dispatch(UpdatePairingRequestSuccess());
        } catch (error) {
            dispatch(UpdatePairingRequestError());
        }
    };

const GetAllEngagementsBegin = () => ({
    type: EngagementsTypes.GET_ENGAGEMENTS_BEGIN,
});
const GetAllEngagementsSuccess = (payload) => ({
    type: EngagementsTypes.GET_ENGAGEMENTS_SUCCESS,
    payload,
});
const GetAllEngagementsError = () => ({
    type: EngagementsTypes.GET_ENGAGEMENTS_ERROR,
});

export const GetAllEngagements = () => async (dispatch) => {
    dispatch(GetAllEngagementsBegin());

    try {
        const { data } = await WebClient.get('/engagements');
        dispatch(GetAllEngagementsSuccess(data));
    } catch (error) {
        dispatch(GetAllEngagementsError());
    }
};

const GetPaginatedEngagementsBegin = () => ({
    type: EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_BEGIN,
});
const GetPaginatedEngagementsSuccess = (payload) => ({
    type: EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_SUCCESS,
    payload,
});
const GetPaginatedEngagementsError = () => ({
    type: EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_ERROR,
});

export const GetPaginatedEngagements = () => async (dispatch, getState) => {
    dispatch(GetPaginatedEngagementsBegin());

    const paginatedParams = getPaginatedEngagementsParams(getState());
    if (paginatedParams.page !== null) {
        const payload = { ...paginatedParams };
        delete payload.page;
        delete payload.pageSize;
        delete payload.results;
        delete payload.total;

        try {
            const { data } = await WebClient.post(
                `/engagements/${paginatedParams.page}/${paginatedParams.pageSize}`,
                payload,
            );
            dispatch(GetPaginatedEngagementsSuccess(data));
        } catch (error) {
            dispatch(APIError());
            dispatch(GetPaginatedEngagementsError());
        }
    }
};

export const UpdatePaginatedEngagementsParams = (payload) => ({
    type: EngagementsTypes.UPDATE_PAGINATED_ENGAGEMENTS_PARAMS,
    payload,
});

export const ClearPaginatedEngagements = () => ({
    type: EngagementsTypes.CLEAR_PAGINATED_ENGAGEMENTS,
});

const DeleteEngagementBegin = () => ({
    type: EngagementsTypes.DELETE_ENGAGEMENT_BEGIN,
});
const DeleteEngagementSuccess = () => ({
    type: EngagementsTypes.DELETE_ENGAGEMENT_SUCCESS,
});
const DeleteEngagementError = () => ({
    type: EngagementsTypes.DELETE_ENGAGEMENT_ERROR,
});

export const DeleteEngagement = (id, onSuccess) => async (dispatch) => {
    dispatch(DeleteEngagementBegin());

    try {
        await WebClient.delete(`/engagements/${id}`);
        dispatch(DeleteEngagementSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(DeleteEngagementError());
    }
};

const UpdateEngagementBegin = () => ({
    type: EngagementsTypes.UPDATE_ENGAGEMENT_BEGIN,
});
const UpdateEngagementSuccess = () => ({
    type: EngagementsTypes.UPDATE_ENGAGEMENT_SUCCESS,
});
const UpdateEngagementError = () => ({
    type: EngagementsTypes.UPDATE_ENGAGEMENT_ERROR,
});

export const UpdateEngagement = (payload, id, onSuccess) => async (dispatch) => {
    dispatch(UpdateEngagementBegin());

    try {
        // redacted can't be changed after creation
        const cleanPayload = { ...payload };
        delete cleanPayload.redacted;
        const { data } = await WebClient.patch(`/engagements/${id}`, cleanPayload);
        dispatch(UpdateEngagementSuccess());
        if (onSuccess) onSuccess({ firmId: data.firmId, engagementId: data.id });
        dispatch(APISuccess('Success editing engagement'));
    } catch (error) {
        dispatch(UpdateEngagementError());
        dispatch(APIError('Error editing engagement'));
    }
};

const CreateEngagementBegin = () => ({
    type: EngagementsTypes.CREATE_ENGAGEMENT_BEGIN,
});
const CreateEngagementSuccess = () => ({
    type: EngagementsTypes.CREATE_ENGAGEMENT_SUCCESS,
});
const CreateEngagementError = () => ({
    type: EngagementsTypes.CREATE_ENGAGEMENT_ERROR,
});

export const CreateEngagement = (payload, onSuccess) => async (dispatch) => {
    dispatch(CreateEngagementBegin());

    try {
        const { data } = await WebClient.post('/engagements', payload);
        dispatch(CreateEngagementSuccess());
        if (onSuccess) onSuccess({ firmId: data.firmId, engagementId: data.id });
        dispatch(APISuccess('Success creating engagement'));
    } catch (error) {
        dispatch(CreateEngagementError());
        dispatch(APIError('Error creating engagement'));
    }
};

const AddCoachesBegin = () => ({
    type: EngagementsTypes.ADD_COACHES_BEGIN,
});
const AddCoachesSuccess = () => ({
    type: EngagementsTypes.ADD_COACHES_SUCCESS,
});
const AddCoachesError = () => ({
    type: EngagementsTypes.ADD_COACHES_ERROR,
});

export const AddCoaches = (engagementId, coachIds, onSuccess) => async (dispatch) => {
    dispatch(AddCoachesBegin());

    try {
        await WebClient.post(`/engagements/${engagementId}/coaches`, { coachIds });
        dispatch(AddCoachesSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(AddCoachesError());
        dispatch(APIError('Error adding instructor'));
    }
};

const AddTalentBegin = () => ({
    type: EngagementsTypes.ADD_TALENT_BEGIN,
});
const AddTalentSuccess = () => ({
    type: EngagementsTypes.ADD_TALENT_SUCCESS,
});
const AddTalentError = () => ({
    type: EngagementsTypes.ADD_TALENT_ERROR,
});

export const AddTalent = (engagementId, users, onSuccess) => async (dispatch) => {
    dispatch(AddTalentBegin());

    const payload = { users: [] };
    Object.keys(users).forEach((key) => {
        payload.users.push({
            id: key,
            engagementPrivileges: users[key],
        });
    });

    try {
        await WebClient.post(`/engagements/${engagementId}/users`, payload);
        dispatch(AddTalentSuccess());
        if (onSuccess) onSuccess(payload.users.length);
    } catch (error) {
        dispatch(AddTalentError());
        dispatch(APIError('Error adding engagement users.'));
    }
};

const RemoveUserBegin = () => ({
    type: EngagementsTypes.REMOVE_USER_BEGIN,
});
const RemoveUserSuccess = () => ({
    type: EngagementsTypes.REMOVE_USER_SUCCESS,
});
const RemoveUserError = () => ({
    type: EngagementsTypes.REMOVE_USER_ERROR,
});

export const RemoveUser = (engagementId, userIds, onSuccess) => async (dispatch) => {
    dispatch(RemoveUserBegin());

    try {
        await WebClient.delete(`/engagements/${engagementId}/users`, { data: { userIds } });
        dispatch(RemoveUserSuccess());
        dispatch(APISuccess('Successfully removed user'));
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(RemoveUserError());
        dispatch(APIError('Error removing user'));
    }
};

const GetPaginatedSessionsBegin = () => ({
    type: EngagementsTypes.GET_PAGINATED_SESSIONS_BEGIN,
});
const GetPaginatedSessionsSuccess = (payload) => ({
    type: EngagementsTypes.GET_PAGINATED_SESSIONS_SUCCESS,
    payload,
});
const GetPaginatedSessionsError = () => ({
    type: EngagementsTypes.GET_PAGINATED_SESSIONS_ERROR,
});

export const GetPaginatedSessions = (engagementId) => async (dispatch, getState) => {
    dispatch(GetPaginatedSessionsBegin());

    const paginatedParams = getPaginatedSessionsParams(getState());
    if (paginatedParams.page !== null) {
        const payload = { ...paginatedParams };
        delete payload.page;
        delete payload.pageSize;
        delete payload.results;
        delete payload.total;

        try {
            const { data } = await WebClient.post(
                `/engagements/${engagementId}/sessions/${paginatedParams.page}/${paginatedParams.pageSize}`,
                payload,
            );
            dispatch(GetPaginatedSessionsSuccess(data));
        } catch (error) {
            dispatch(APIError());
            dispatch(GetPaginatedSessionsError());
        }
    }
};

export const UpdatePaginatedSessionsParams = (payload) => ({
    type: EngagementsTypes.UPDATE_PAGINATED_SESSIONS_PARAMS,
    payload,
});

export const ClearPaginatedSessions = () => ({
    type: EngagementsTypes.CLEAR_PAGINATED_SESSIONS,
});
