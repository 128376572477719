import EngagementsTypes from '../action-types/engagements';

const internals = {
    initial: () => ({
        engagements: [],
        learningTopics: [],
        paginatedEngagements: {
            params: {
                page: null,
                pageSize: 10,
                sortBy: 'name',
                sortOrder: 'asc',
                filter: {},
            },
            results: null,
            total: null,
        },
        paginatedEngagementStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        paginatedSessions: {
            params: {
                page: null,
                pageSize: 10,
                sortBy: 'scheduledTime',
                sortOrder: 'asc',
                filter: {},
            },
            results: null,
            total: null,
        },
        paginatedSessionsStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        deleteEngagementStatus: {
            error: false,
            completed: false,
        },
    }),
};

const EngagementsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
        case EngagementsTypes.UPDATE_PAGINATED_ENGAGEMENTS_PARAMS: {
            const newState = { ...state };
            newState.paginatedEngagements.params = {
                ...state.paginatedEngagements.params,
                ...payload,
            };
            return newState;
        }
        case EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_BEGIN:
            return {
                ...state,
                paginatedEngagementsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_ERROR:
            return {
                ...state,
                paginatedEngagementsStatus: {
                    error: true,
                    errorMsg: '',
                    completed: true,
                },
            };
        case EngagementsTypes.GET_PAGINATED_ENGAGEMENTS_SUCCESS:
            return {
                ...state,
                paginatedEngagementsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
                paginatedEngagements: {
                    ...state.paginatedEngagements,
                    results: payload.results,
                    total: payload.total,
                },
            };
        case EngagementsTypes.CLEAR_PAGINATED_ENGAGEMENTS:
            return {
                ...state,
                paginatedEngagementsStatus: internals.initial().paginatedEngagementsStatus,
                paginatedEngagements: internals.initial().paginatedEngagements,
                paginatedEngagementsTypes: internals.initial().paginatedEngagementsTypes,
            };
        case EngagementsTypes.DELETE_ENGAGEMENT_BEGIN:
            return {
                ...state,
                deleteEngagementStatus: {
                    error: false,
                    completed: false,
                },
            };
        case EngagementsTypes.DELETE_ENGAGEMENT_SUCCESS:
            return {
                ...state,
                deleteEngagementStatus: {
                    error: false,
                    completed: true,
                },
            };
        case EngagementsTypes.DELETE_ENGAGEMENT_ERROR:
            return {
                ...state,
                deleteEngagementStatus: {
                    error: true,
                    completed: true,
                },
            };
        case EngagementsTypes.GET_ENGAGEMENTS_BEGIN:
            return {
                ...state,
                engagements: [],
            };
        case EngagementsTypes.GET_ENGAGEMENTS_SUCCESS:
            return {
                ...state,
                engagements: payload,
            };
        case EngagementsTypes.GET_LIBRARIES_BEGIN:
            return {
                ...state,
                learningTopics: [],
            };
        case EngagementsTypes.GET_LIBRARIES_SUCCESS:
            return {
                ...state,
                learningTopics: payload,
            };
        // engagement sessions
        case EngagementsTypes.UPDATE_PAGINATED_SESSIONS_PARAMS: {
            const newState = { ...state };
            newState.paginatedSessions.params = {
                ...state.paginatedSessions.params,
                ...payload,
            };
            return newState;
        }
        case EngagementsTypes.GET_PAGINATED_SESSIONS_BEGIN:
            return {
                ...state,
                paginatedSessionsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case EngagementsTypes.GET_PAGINATED_SESSIONS_ERROR:
            return {
                ...state,
                paginatedSessionsStatus: {
                    error: true,
                    errorMsg: '',
                    completed: true,
                },
            };
        case EngagementsTypes.GET_PAGINATED_SESSIONS_SUCCESS:
            return {
                ...state,
                paginatedSessionsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
                paginatedSessions: {
                    ...state.paginatedSessions,
                    results: payload.results,
                    total: payload.total,
                },
            };
        case EngagementsTypes.CLEAR_PAGINATED_SESSIONS:
            return {
                ...state,
                paginatedSessionsStatus: internals.initial().paginatedSessionsStatus,
                paginatedSessions: internals.initial().paginatedSessions,
                paginatedSessionsTypes: internals.initial().paginatedSessionsTypes,
            };

        default:
        // do nothing
    }
    return state;
};

export default EngagementsReducer;
