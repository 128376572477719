import FirmsTypes from '../action-types/firms';

const internals = {
    initial: () => ({
        firms: [],
        paginatedFirms: {
            params: {
                page: null,
                pageSize: 10,
                sortBy: 'name',
                sortOrder: 'asc',
                filter: {},
            },
            results: null,
            total: null,
        },
        paginatedFirmsStatus: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        deleteFirmStatus: {
            error: false,
            completed: false,
        },
    }),
};

const FirmsReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
        case FirmsTypes.UPDATE_PAGINATED_FIRMS_PARAMS: {
            const newState = { ...state };
            newState.paginatedFirms.params = {
                ...state.paginatedFirms.params,
                ...payload,
            };
            return newState;
        }
        case FirmsTypes.GET_PAGINATED_FIRMS_BEGIN:
            return {
                ...state,
                paginatedFirmsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case FirmsTypes.GET_PAGINATED_FIRMS_ERROR:
            return {
                ...state,
                paginatedFirmsStatus: {
                    error: true,
                    errorMsg: '',
                    completed: true,
                },
            };
        case FirmsTypes.GET_PAGINATED_FIRMS_SUCCESS:
            return {
                ...state,
                paginatedFirmsStatus: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
                paginatedFirms: {
                    ...state.paginatedFirms,
                    results: payload.results,
                    total: payload.total,
                },
            };
        case FirmsTypes.CLEAR_PAGINATED_FIRMS:
            return {
                ...state,
                paginatedFirmsStatus: internals.initial().paginatedFirmsStatus,
                paginatedFirms: internals.initial().paginatedFirms,
                paginatedFirmsTypes: internals.initial().paginatedFirmsTypes,
            };
        case FirmsTypes.DELETE_FIRM_BEGIN:
            return {
                ...state,
                deleteFirmStatus: {
                    error: false,
                    completed: false,
                },
            };
        case FirmsTypes.DELETE_FIRM_SUCCESS:
            return {
                ...state,
                deleteFirmStatus: {
                    error: false,
                    completed: true,
                },
            };
        case FirmsTypes.DELETE_FIRM_ERROR:
            return {
                ...state,
                deleteFirmStatus: {
                    error: true,
                    completed: true,
                },
            };
        case FirmsTypes.GET_FIRMS_BEGIN:
            return {
                ...state,
                firms: [],
            };
        case FirmsTypes.GET_FIRMS_SUCCESS:
            return {
                ...state,
                firms: payload,
            };

        default:
        // do nothing
    }
    return state;
};

export default FirmsReducer;
