const nullifyEmptyStrings = (obj) => {
    const newObj = { ...obj };
    Object.keys(newObj).forEach((key) => {
        if (newObj[key] === '') {
            newObj[key] = null;
        }
    });
    return newObj;
};

export default nullifyEmptyStrings;
