import WebClient from '../utils/web-client';
import ResourcesTypes from '../action-types/resources';
import { APIError, APISuccess } from './app';
import removeEmptyArrays from '../utils/remove-empty-arrays';
import removeEmptyKeys from '../utils/remove-empty-keys';
import nullifyEmptyStrings from '../utils/nullify-empty-strings';

import { getPaginatedResourcesParams } from '../selectors/resources';

export const RecordResourceClick = (resourceId) => async () => {
    try {
        await WebClient.post('/resource-clicks', { resourceId });
    } catch (error) {
        // the user doesn't need to know about this
        // update: we don't want the console to know about it either
        // eslint-disable-next-line no-console
        // console.error('Error recording resource click', error);
    }
};

const GetRemoteResourceParametersBegin = () => ({
    type: ResourcesTypes.GET_REMOTE_PARAMETERS_BEGIN,
});
const GetRemoteResourceParametersSuccess = (payload) => ({
    type: ResourcesTypes.GET_REMOTE_PARAMETERS_SUCCESS,
    payload,
});
const GetRemoteResourceParametersError = () => ({
    type: ResourcesTypes.GET_REMOTE_PARAMETERS_ERROR,
});

export const GetRemoteResourceParameters = () => async (dispatch) => {
    dispatch(GetRemoteResourceParametersBegin());

    try {
        const { data } = await WebClient.get('/resources/filter-options');
        dispatch(GetRemoteResourceParametersSuccess(data));
    } catch (error) {
        dispatch(GetRemoteResourceParametersError());
    }
};

const GetLibrariesBegin = () => ({
    type: ResourcesTypes.GET_LIBRARIES_BEGIN,
});
const GetLibrariesSuccess = (payload) => ({
    type: ResourcesTypes.GET_LIBRARIES_SUCCESS,
    payload,
});
const GetLibrariesError = () => ({
    type: ResourcesTypes.GET_LIBRARIES_ERROR,
});

export const GetLibraries = () => async (dispatch) => {
    dispatch(GetLibrariesBegin());

    try {
        const { data } = await WebClient.get('/libraries/learning-topics/resources');
        dispatch(GetLibrariesSuccess(data));
    } catch (error) {
        dispatch(GetLibrariesError());
    }
};

const GetPaginatedResourcesBegin = () => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_BEGIN,
});
const GetPaginatedResourcesSuccess = (payload) => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_SUCCESS,
    payload,
});
const GetPaginatedResourcesError = () => ({
    type: ResourcesTypes.GET_PAGINATED_RESOURCES_ERROR,
});

export const GetPaginatedResources = () => async (dispatch, getState) => {
    dispatch(GetPaginatedResourcesBegin());

    const paginatedParams = getPaginatedResourcesParams(getState());
    // this is gross, but paginatedParams.page is set to null when we reload
    // this is how the FE component knows if the data has been hydrated from the url params
    // an unintended consequence is that we don't the data present at this moment in time
    if (paginatedParams.page !== null) {
        const payload = { ...paginatedParams };
        delete payload.page;
        delete payload.pageSize;
        delete payload.results;
        delete payload.total;

        // turn an array of strings into integers
        if (payload.learningTopicIds?.length > 0)
            payload.learningTopicIds = payload.learningTopicIds.map((item) => parseInt(item, 10));
        payload.filter = removeEmptyArrays(payload.filter);

        try {
            const { data } = await WebClient.post(
                `/resources/${paginatedParams.page}/${paginatedParams.pageSize}`,
                payload,
            );
            dispatch(GetPaginatedResourcesSuccess(data));
        } catch (error) {
            dispatch(APIError());
            dispatch(GetPaginatedResourcesError());
        }
    }
};

export const UpdatePaginatedResourcesParams = (payload) => ({
    type: ResourcesTypes.UPDATE_PAGINATED_RESOURCES_PARAMS,
    payload,
});

export const ClearPaginatedResources = () => ({
    type: ResourcesTypes.CLEAR_PAGINATED_RESOURCES,
});

const DeleteResourceBegin = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_BEGIN,
});
const DeleteResourceSuccess = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_SUCCESS,
});
const DeleteResourceError = () => ({
    type: ResourcesTypes.DELETE_RESOURCE_ERROR,
});

export const DeleteResource = (id, onSuccess) => async (dispatch) => {
    dispatch(DeleteResourceBegin());

    try {
        await WebClient.delete(`/resources/${id}`);
        dispatch(DeleteResourceSuccess());
        if (onSuccess) onSuccess();
    } catch (error) {
        dispatch(DeleteResourceError());
    }
};

const UpdateResourceBegin = () => ({
    type: ResourcesTypes.UPDATE_RESOURCE_BEGIN,
});
const UpdateResourceSuccess = () => ({
    type: ResourcesTypes.UPDATE_RESOURCE_SUCCESS,
});
const UpdateResourceError = () => ({
    type: ResourcesTypes.UPDATE_RESOURCE_ERROR,
});

export const UpdateResource = (id, incomingData, onSuccess) => async (dispatch) => {
    dispatch(UpdateResourceBegin());

    let payload = removeEmptyArrays(incomingData);
    payload = nullifyEmptyStrings(payload);
    payload.learningTopicIds = payload.learningTopicIds.map((item) => parseInt(item, 10));

    try {
        const { data } = await WebClient.patch(`/resources/${id}`, payload);
        dispatch(UpdateResourceSuccess());
        dispatch(GetPaginatedResources());
        dispatch(GetLibraries());
        if (onSuccess) onSuccess(data.id);
    } catch (error) {
        dispatch(UpdateResourceError());
        dispatch(APIError('Error editing resource'));
    }
};

const CreateResourceBegin = () => ({
    type: ResourcesTypes.CREATE_RESOURCE_BEGIN,
});
const CreateResourceSuccess = () => ({
    type: ResourcesTypes.CREATE_RESOURCE_SUCCESS,
});
const CreateResourceError = () => ({
    type: ResourcesTypes.CREATE_RESOURCE_ERROR,
});

export const CreateResource = (incomingData, onSuccess) => async (dispatch) => {
    dispatch(CreateResourceBegin());

    let payload = removeEmptyArrays(incomingData);
    payload = removeEmptyKeys(payload);
    payload.learningTopicIds = payload.learningTopicIds.map((item) => parseInt(item, 10));

    try {
        const { data } = await WebClient.post('/resources', payload);
        dispatch(CreateResourceSuccess());
        dispatch(GetPaginatedResources());
        dispatch(GetLibraries());
        if (onSuccess) onSuccess(data?.id);
        dispatch(APISuccess('Success creating resource'));
    } catch (error) {
        dispatch(CreateResourceError());
        dispatch(APIError('Error creating resource'));
    }
};

// This is a duplicate of UpdateResource, but we hardcode the status to archived
export const ArchiveResource = (id, onSuccess) => async (dispatch) => {
    dispatch(UpdateResourceBegin());

    try {
        await WebClient.patch(`/resources/${id}`, { status: 'archived' });
        dispatch(UpdateResourceSuccess());
        dispatch(GetPaginatedResources());
        if (onSuccess) onSuccess();
        dispatch(APISuccess('Success archiving resource'));
    } catch (error) {
        dispatch(UpdateResourceError());
        dispatch(APIError('Error archiving resource'));
    }
};
