const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    API_ERROR: true,
    API_SUCCESS: true,
    API_RESET: true,
    SWITCH_SIDE_BAR: true,
    CONFIRM_PROFILE_PROMPT_SHOWN: true,
    GET_REACTIONS_BEGIN: true,
    GET_REACTIONS_SUCCESS: true,
    GET_REACTIONS_ERROR: true,
});
