const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    ADD_USER_BEGIN: true,
    ADD_USER_SUCCESS: true,
    ADD_USER_ERROR: true,
    GET_PAGINATED_USERS_BEGIN: true,
    GET_PAGINATED_USERS_SUCCESS: true,
    GET_PAGINATED_USERS_ERROR: true,
    UPDATE_PAGINATED_USERS_PARAMS: true,
    CLEAR_PAGINATED_USERS: true,
    DELETE_USER_BEGIN: true,
    DELETE_USER_SUCCESS: true,
    DELETE_USER_ERROR: true,
    ASSIGN_COACH_BEGIN: true,
    ASSIGN_COACH_ERROR: true,
    ASSIGN_COACH_SUCCESS: true,
    UNASSIGN_COACH_BEGIN: true,
    UNASSIGN_COACH_ERROR: true,
    UNASSIGN_COACH_SUCCESS: true,
    UPDATE_COACH_BEGIN: true,
    UPDATE_COACH_SUCCESS: true,
    UPDATE_COACH_ERROR: true,
    GET_CERTIFICATIONS_BEGIN: true,
    GET_CERTIFICATIONS_SUCCESS: true,
    GET_CERTIFICATIONS_ERROR: true,
    GET_PERMISSIONS_BEGIN: true,
    GET_PERMISSIONS_SUCCESS: true,
    GET_PERMISSIONS_ERROR: true,
});
