const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    SETUP_USER: true,
    GET_USER_BEGIN: true,
    GET_USER_SUCCESS: true,
    GET_USER_ERROR: true,
    UPDATE_USER_BEGIN: true,
    UPDATE_USER_SUCCESS: true,
    UPDATE_USER_ERROR: true,
});
