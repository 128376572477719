import AuthTypes from '../action-types/auth';

const internals = {
    initial: () => ({
        isAuthenticated: false,
        error: false,
        errorMsg: '',
        credentials: {
            token: '',
        },
        resetPass: {
            error: false,
            errorMsg: '',
            completed: false,
        },
        forgotPass: {
            error: false,
            errorMsg: '',
            completed: false,
        },
    }),
};

const AuthReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;

    switch (type) {
        case AuthTypes.INITIALIZE_APP:
            return {
                ...state,
                error: false,
                errorMsg: '',
                resetPass: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
                forgotPass: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case AuthTypes.LOGIN_BEGIN:
            return {
                ...state,
                error: false,
                errorMsg: '',
                isAuthenticated: false,
            };
        case AuthTypes.LOGIN_ERROR:
            return {
                ...state,
                isAuthenticated: false,
                error: true,
                errorMsg: payload.errorMsg,
            };
        // LOGIN_SUCCESS moves user to 2FA, no data to save
        case AuthTypes.LOGOUT:
            return {
                ...internals.initial(),
            };
        case AuthTypes.LOGIN_CONFIRMATION_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                credentials: {
                    token: payload.token,
                },
                error: false,
                errorMsg: '',
            };
        case AuthTypes.RESET_PASSWORD_BEGIN:
            return {
                ...state,
                resetPass: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case AuthTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPass: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
            };
        case AuthTypes.RESET_PASSWORD_ERROR:
            return {
                ...state,
                resetPass: {
                    error: true,
                    errorMsg: payload.errorMsg,
                    completed: true,
                },
            };
        case AuthTypes.FORGOT_PASSWORD_BEGIN:
            return {
                ...state,
                forgotPass: {
                    error: false,
                    errorMsg: '',
                    completed: false,
                },
            };
        case AuthTypes.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgotPass: {
                    error: false,
                    errorMsg: '',
                    completed: true,
                },
            };
        case AuthTypes.FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                forgotPass: {
                    error: true,
                    errorMsg: payload.errorMsg,
                    completed: true,
                },
            };

        default:
        // do nothing
    }
    return state;
};

export default AuthReducer;
