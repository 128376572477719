const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    GET_RESOURCES_BEGIN: true,
    GET_RESOURCES_SUCCESS: true,
    GET_RESOURCES_ERROR: true,
    GET_LIBRARIES_BEGIN: true,
    GET_LIBRARIES_SUCCESS: true,
    GET_LIBRARIES_ERROR: true,
    GET_PAGINATED_RESOURCES_BEGIN: true,
    GET_PAGINATED_RESOURCES_SUCCESS: true,
    GET_PAGINATED_RESOURCES_ERROR: true,
    UPDATE_PAGINATED_RESOURCES_PARAMS: true,
    CLEAR_PAGINATED_RESOURCES: true,
    DELETE_RESOURCE_BEGIN: true,
    DELETE_RESOURCE_SUCCESS: true,
    DELETE_RESOURCE_ERROR: true,
    UPDATE_RESOURCE_BEGIN: true,
    UPDATE_RESOURCE_SUCCESS: true,
    UPDATE_RESOURCE_ERROR: true,
    GET_REMOTE_PARAMETERS_BEGIN: true,
    GET_REMOTE_PARAMETERS_SUCCESS: true,
    GET_REMOTE_PARAMETERS_ERROR: true,
    CREATE_RESOURCE_BEGIN: true,
    CREATE_RESOURCE_SUCCESS: true,
    CREATE_RESOURCE_ERROR: true,
});
