const KeyMirror = require('keymirror');

module.exports = KeyMirror({
    CHECK_TOKEN_BEGIN: true,
    CHECK_TOKEN_SUCCESS: true,
    CHECK_TOKEN_ERROR: true,
    LOGIN_BEGIN: true,
    LOGIN_SUCCESS: true,
    LOGIN_ERROR: true,
    LOGOUT: true,
    RESET_PASSWORD_BEGIN: true,
    RESET_PASSWORD_SUCCESS: true,
    RESET_PASSWORD_ERROR: true,
    FORGOT_PASSWORD_BEGIN: true,
    FORGOT_PASSWORD_SUCCESS: true,
    FORGOT_PASSWORD_ERROR: true,
    INITIALIZE_APP: true,
    NO_TOKEN: true,
    LOGIN_CONFIRMATION_BEGIN: true,
    LOGIN_CONFIRMATION_SUCCESS: true,
    LOGIN_CONFIRMATION_ERROR: true,
    SETUP_AUTH_BEGIN: true,
    SETUP_AUTH_SUCCESS: true,
    SETUP_AUTH_ERROR: true,
    UPDATE_PASSWORD_BEGIN: true,
    UPDATE_PASSWORD_SUCCESS: true,
    UPDATE_PASSWORD_ERROR: true,
    SET_PASSWORD_BEGIN: true,
    SET_PASSWORD_ERROR: true,
    SET_PASSWORD_SUCCESS: true,
    RESEND_2FA_BEGIN: true,
    RESEND_2FA_SUCCESS: true,
    RESEND_2FA_ERROR: true,
});
