import UserTypes from '../action-types/user';
import AuthTypes from '../action-types/auth';

const internals = {
    initial: () => ({
        user: {},
    }),
};

const UserReducer = (stateParam, action) => {
    const state = stateParam || internals.initial();

    const { payload, type } = action;
    switch (type) {
        case UserTypes.SETUP_USER:
        case UserTypes.GET_USER_SUCCESS:
        case UserTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload,
                },
            };

        // clear all stored data on logout
        case AuthTypes.LOGOUT:
            return {
                ...internals.initial(),
            };

        default:
        // do nothing
    }
    return state;
};

export default UserReducer;
