export default {
    greyDark: '#3D3D3D',
    greyMed: '#61676C',
    greyLight: '#E9EAEB',
    grey: '#C3C4C4',
    blueDark: '#314F6C',
    blueMed: '#6690B6',
    blueLight: '#BFD5E9',
    blueLightest: '#F3F6F9',
    blueBackground: '#f3f5f9',
    blueLogo: '#3A5D80',
    blueLogoRGB: '58, 93, 128',

    green: '#35937C',
    greenRGB: '53, 147, 124',
    teal: '#63B1BC',

    orange: '#E87722',
    orange2: '#F6C9A7',
    orange3: '#FAE4D3',
    errorYellow: '#F1B434',

    videoResources: '#C63662',
    articleResources: '#63B1BC',
    audioResources: '#642F6C',
    worksheetResources: '#F1B434',

    error: '#B60000',
    success: '#13AE47',

    white: '#ffffff',
    black: '#2F3135',
};
