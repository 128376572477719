import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CheckIcon from '@mui/icons-material/Check';

const NotificationSnackbar = ({ isOpen, handleClose, text = '', type = 'success', autoHideDisabled = null }) => (
    <Snackbar
        open={isOpen}
        autoHideDuration={autoHideDisabled ? null : 5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: type === 'success' ? 'bottom' : 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
    >
        <Alert
            onClose={handleClose}
            severity={type}
            iconMapping={{
                error: <ReportProblemOutlinedIcon fontSize="inherit" />,
                success: <CheckIcon fontSize="inherit" />,
            }}
            data-cy={`notification-snackbar-${type}`}
            variant="filled"
            style={{ fontSize: '1.5rem' }}
        >
            {text}
        </Alert>
    </Snackbar>
);

NotificationSnackbar.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    text: PropTypes.string,
    type: PropTypes.oneOf(['success', 'error', 'info']),
    autoHideDisabled: PropTypes.bool,
};

export default NotificationSnackbar;
