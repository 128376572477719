import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';
import { isEqual } from 'lodash';

const getFirmState = (state) => state.firms;

export const getAllFirms = createSelector(getFirmState, (firmState) => firmState.firms);
// a selector that accepts an id and returns the firm associate with that id
// const firm = useSelector((state) => getFirmById(state, parseInt(firmId, 10)));
export const getFirmById = createSelector([getFirmState, (_, id) => id], (firmState, id) =>
    firmState?.firms?.find((firm) => firm.id === id),
);

export const getAllFirmsForMenus = createSelector(getFirmState, (firmState) =>
    firmState.firms?.map((item) => ({ key: item.id, value: item.name })),
);

export const getPaginatedFirms = createSelector(getFirmState, (firmState) => firmState.paginatedFirms.results);

export const getPaginatedFirmsStatus = createSelector(getFirmState, (firmState) => firmState.paginatedFirmsStatus);

// create a "selector creator" that uses lodash.isequal instead of ===
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

// used to present 'getPaginatedFirmParams' with a cleaned state to compare
const cleanPaginatedFirmParams = createSelector(getFirmState, (state) => {
    const cleanedParams = { ...state.paginatedFirms.params };
    return cleanedParams;
});
// uses lodash.isequal to compare state of the object
export const getPaginatedFirmsParams = createDeepEqualSelector(
    cleanPaginatedFirmParams,
    (cleanedState) => cleanedState,
);

export const getTotalFirmsCount = createSelector(getFirmState, (firmState) => firmState.paginatedFirms.total);
