const removeEmptyArrays = (filterObject) => {
    const processedObject = { ...filterObject };
    // eslint-disable-next-line no-restricted-syntax
    for (const key in processedObject) {
        if (
            Array.isArray(processedObject[key]) &&
            (processedObject[key].length === 0 ||
                processedObject[key][0] === '' ||
                processedObject[key][0] === 'undefined')
        ) {
            delete processedObject[key];
        }
    }
    return processedObject;
};

export default removeEmptyArrays;
