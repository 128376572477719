import { createSelector } from 'reselect';

const getAuthState = (state) => state.auth;

export const isAuthenticated = createSelector(getAuthState, (authState) => authState.isAuthenticated);

export const isNewUser = createSelector(getAuthState, (authState) => authState.isNewUser);

const getCredentials = createSelector(getAuthState, (authState) => authState.credentials);

export const getToken = createSelector(getCredentials, (credentials) => (credentials ? credentials.token : null));

export const getAuthError = createSelector(getAuthState, (authState) => authState.error);

export const getAuthErrorMessage = createSelector(getAuthState, (authState) => authState.errorMsg);

export const isResetSuccessful = createSelector(
    getAuthState,
    (authState) => authState.resetPass.completed && !authState.resetPass.error,
);

export const getResetError = createSelector(getAuthState, (authState) => authState.resetPass.errorMsg);

export const isForgotPassSuccessful = createSelector(
    getAuthState,
    (authState) => authState.forgotPass.completed && !authState.forgotPass.error,
);

export const getForgotPassError = createSelector(getAuthState, (authState) => authState.forgotPass.error);

export const getForgotPassErrorMessage = createSelector(getAuthState, (authState) => authState.forgotPass.errorMsg);
