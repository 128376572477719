import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import SnackbarContent from '@mui/material/SnackbarContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import styled from '@mui/material/styles/styled';
import { useDispatch } from 'react-redux';

import Colors from '../../styles/colors';
import { UpdateUser } from '../../actions/user';

const StyledSnackbarContent = styled(SnackbarContent)(() => ({
    backgroundColor: Colors.blueLight,
    color: Colors.greyDark,
    borderRadius: 10,
    padding: 20,
    boxShadow: 'none',
    '& a': {
        color: Colors.blueDark,
        fontWeight: 700,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const CookieDialog = () => {
    const dispatch = useDispatch();
    const handleAnswer = (answer) => {
        // Update user
        dispatch(UpdateUser({ cookies: answer }));
    };

    const action = (
        <>
            <Button size="small" onClick={() => handleAnswer(true)}>
                Accept All
            </Button>
            <Button size="small" onClick={() => handleAnswer(false)}>
                Required Only
            </Button>
        </>
      );

    const message = (
        <>
            <Typography variant="h5" style={{ color: Colors.greyDark }}>About cookies on this site</Typography>
            Our website requires some cookies to function properly. In addition, other cookies may be used with your consent to analyze site usage, improve the user experience and for advertising. For more information on how we use cookies, please review our <Link to="/privacy-policy">privacy policy</Link>.
        </>
    );
    return (
        <Box sx={{ position: 'fixed', bottom: 30, width: '100%', zIndex: 100 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack spacing={2} sx={{ maxWidth: 620 }}>
                <StyledSnackbarContent
                    message={message}
                    action={action}
                />
                </Stack>
            </Box>
        </Box>
  );
};

export default CookieDialog;
