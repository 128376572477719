import WebClient from '../utils/web-client';
import AppTypes from '../action-types/app';

export const ResetAPIStatus = () => ({
    type: AppTypes.API_RESET,
});

export const SwitchSidebarExpansion = () => ({
    type: AppTypes.SWITCH_SIDE_BAR,
});

export const APIError = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_ERROR,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const APISuccess = (text = null, autoHideDisabled = null) => ({
    type: AppTypes.API_SUCCESS,
    payload: {
        text,
        autoHideDisabled,
    },
});

export const ConfirmProfilePromptShown = () => ({
    type: AppTypes.CONFIRM_PROFILE_PROMPT_SHOWN,
});

export const GetReactionsBegin = () => ({
    type: AppTypes.GET_REACTIONS_BEGIN,
});
const GetReactionsSuccess = (payload) => ({
    type: AppTypes.GET_REACTIONS_SUCCESS,
    payload,
});
const GetReactionsError = () => ({
    type: AppTypes.GET_REACTIONS_ERROR,
});
export const GetReactions = () => async (dispatch) => {
    dispatch(GetReactionsBegin());

    try {
        const { data } = await WebClient.get('/reactions');
        dispatch(GetReactionsSuccess(data));
    } catch {
        dispatch(GetReactionsError());
    }
};
